import { type CustomizedText } from '@kijiji/generated/graphql-types'

import { isCustomizedTextType } from '@/types/contentful'
import { getColorFromHexOrConst } from '@/utils/colors'

import { TextFragmentAssemblerSpan } from './styled'

export type TextFragment = {
  style?: { color: string; isBold: boolean }
  text: string
}

export const textFragmentAssembler = (fragments?: Array<CustomizedText | null>) => {
  return (
    fragments?.map((fragment, idx) => {
      if (!isCustomizedTextType(fragment)) return null

      const { style, text } = fragment

      let isBold = false
      let color = 'inherit'

      if (style) {
        isBold = !!style.isBold
        if (style.color) {
          color = getColorFromHexOrConst(style.color)
        }
      }

      return (
        <TextFragmentAssemblerSpan key={`frag-${text}-${idx}`} color={color} isBold={isBold}>
          {text}
        </TextFragmentAssemblerSpan>
      )
    }) || null
  )
}
