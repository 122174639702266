import { type ThemeProps } from '@kijiji/theme'
import styled from 'styled-components'

import { HeadlineText } from '@/ui/atoms/headline-text'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

import { ImageCustom } from '../image'
import { type VerticalListingCardProps } from './VerticalListingCard'

const getCardWidthBasedOnRowCount = (itemsPerRow?: number) =>
  !itemsPerRow ? 'unset' : `${100 / itemsPerRow}%`

const VERTICAL_LISTING_IMAGE_HEIGHT = { small: '10rem', regular: '15rem' }

const verticalCategoryCardContainer = `
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
`

const verticalAdCardContainer = (theme: ThemeProps) => `
  & a:visited h4 {
    color: ${theme.colors.violet.dark1};
  }

  &:hover,
  &:focus {
    & h4 {
      color: ${theme.colors.blue.dark1};
    }
  }
`

export const VerticalListingCardContainer = styled.div<
  Pick<VerticalListingCardProps, 'minWidth' | 'itemsPerRow' | 'listingImageSize' | 'variant'>
>(({ theme, minWidth, itemsPerRow, variant = 'listing' }) => {
  return `
      align-self: stretch;
      background-color: ${theme.colors.white};
      border-radius: ${theme.borderRadius.small};
      box-shadow: 0 .1rem .1rem 0 rgba(0,0,0,.1);
      display: flex;
      flex-basis: 0;
      flex-direction: column;
      height: 100%;
      margin: ${theme.spacing.mini};
      min-width: ${
        itemsPerRow ? getCardWidthBasedOnRowCount(itemsPerRow?.[2]) : minWidth || '16.6667%'
      };

      ${variant === 'listing' ? verticalAdCardContainer(theme) : verticalCategoryCardContainer}

      // Ensure container to take full height of its parent automatically
      & a {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        text-decoration: none;
      }

      &:hover,
      &:focus {
        box-shadow: 0 .2rem .3rem 0 rgba(0,0,0,.1);
      }

      &:a:visited {
        box-shadow: 0 .2rem .3rem 0 rgba(0,0,0,.1);
      }

      ${MEDIA_QUERIES(theme).medium}{
        max-width: ${getCardWidthBasedOnRowCount(itemsPerRow?.[1])};
      }

      ${MEDIA_QUERIES(theme).large}{
        max-width: ${getCardWidthBasedOnRowCount(itemsPerRow?.[0])};
      }
    `
})

type VerticalListingCardImagWrapperProps = {
  $listingImageSize?: VerticalListingCardProps['listingImageSize']
}

export const VerticalListingCardImageWrapper = styled.div<VerticalListingCardImagWrapperProps>(
  ({ $listingImageSize }) => `
     position: relative;
     height: ${VERTICAL_LISTING_IMAGE_HEIGHT[$listingImageSize || 'regular']}
`
)

type VerticalListingCardImageProps = {
  $listingImageSize?: VerticalListingCardProps['listingImageSize']
}
export const VerticalListingCardImage = styled(ImageCustom)<VerticalListingCardImageProps>(
  ({ theme, $listingImageSize = 'regular' }) => `
  background-color: ${theme.colors.white};
  border-bottom: .1rem solid ${theme.colors.grey.light4};
  max-height: ${VERTICAL_LISTING_IMAGE_HEIGHT[$listingImageSize]};
  object-fit: cover;
  width: 100%;
`
)

export const VerticalListingCardHeadline = styled(HeadlineText)`
  // Truncate title on the second line
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  font-size: 1.4rem;
  overflow: hidden;
`

export const VerticalCategoryListingContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
`

export const VerticalCategoryListingBody = styled.div(
  ({ theme }) => `
  align-items: center;
  background: rgba(55,51,115,.8);
  display: flex;
  flex-direction: column;
  min-height: 10.2rem;
  padding: ${theme.spacing.default} ${theme.spacing.default};
  position: relative;
  width: 100%;
`
)

export const VerticalCategoryListingHeader = styled(HeadlineText)(
  ({ theme }) => `
  font-size: 1.2rem;
  margin-bottom: ${theme.spacing.mini};
`
)
