import { useDecision } from '@optimizely/react-sdk'
import { useSession } from 'next-auth/react'
import { useTheme } from 'styled-components'

import { AppMarketingBanner } from '@/components/homepage/app-marketing-banner/AppMarketingBanner'
import { FeedFallbackComponents } from '@/components/homepage/feed-fallback-components/FeedFallbackComponents'
import { HeroCMSBanner } from '@/components/homepage/hero-cms-banner/HeroCMSBanner'
import { HomepageFeed } from '@/components/homepage/homepage-feed/HomepageFeed'
import { MegaMenu } from '@/components/homepage/mega-menu/MegaMenu'
import { PopularOnKijiji } from '@/components/homepage/popular-on-kijiji/PopularOnKijiji'
import { HomepageSeoMetadata } from '@/components/homepage/seo-metadata/HomepageSeoMetadata'
import { SimilarToFavouritesGallery } from '@/components/homepage/similar-to-favourites/SimilarToFavouritesGallery'
import {
  HomepageAside,
  HomepageContainer,
  HomepageMain,
  HomepageSectionWrapper,
} from '@/components/homepage/styled'
import { useHomepageGetSeoUrl } from '@/components/homepage/useHomepageGetSeoUrl'
import { useHomepageInfiniteFeed } from '@/components/homepage/useHomepageInfiniteFeed'
import { ClientRender } from '@/components/shared/client-render'
import { BaseLayout } from '@/components/shared/layouts/BaseLayout'
import { PAGE_TYPE } from '@/constants/pageTypes'
import { isUserAuthenticated } from '@/domain/user'
import { useGetLocation } from '@/hooks/location/useGetLocation'
import { useLocale } from '@/hooks/useLocale'
import { GptRightrailBottom } from '@/lib/ads/ad-slots/homepage/GptRightrailBottom'
import { GptRightrailMarketing } from '@/lib/ads/ad-slots/homepage/GptRightrailMarketing'
import { GptRightrailTop } from '@/lib/ads/ad-slots/homepage/GptRightrailTop'
import { GptStickyHP } from '@/lib/ads/ad-slots/homepage/GptStickyHP'
import { HomepageAdsWrapper } from '@/lib/ads/ads-wrappers/HomepageAdsWrapper'
import { useTracking } from '@/lib/ga'
import { FEATURE_FLAG } from '@/lib/optimizely'
import { Spacing } from '@/ui/atoms/spacing'
import { ScrollToTopButton } from '@/ui/molecules/scroll-to-top-button'

import { PriceDrop } from '../price-drop/PriceDrop'

type HomepageInnerProps = {
  correlationId: string
  userAgent: string
}

export const HomepageInner = ({ correlationId, userAgent }: HomepageInnerProps) => {
  const { spacing } = useTheme()
  const { apiLocale } = useLocale()
  const { status: userStatus, data: userData } = useSession()
  const { location } = useGetLocation()
  const [priceDropDecision] = useDecision(FEATURE_FLAG.PRICE_DROP_NOTIFICATION)
  const locationId = location.id

  useTracking({
    pageType: PAGE_TYPE.HOMEPAGE,
    data: {
      c: {
        c: { id: 0, n: 'Classifieds' },
        l0: { id: 0, n: 'Classifieds' },
      },
      s: { sr: [], sli: 0 },
    },
  })

  const {
    feedPageInfo,
    feedResults,
    handleLoadMore,
    loadingFeed,
    loadingMore: hpFeedLoadingMore,
    shouldAutoFetchMore,
    shouldShowHomepageFeed,
  } = useHomepageInfiniteFeed({
    correlationId,
    locationArea: location.area,
    locationId,
    userAgent,
  })

  const { getSRPUrl, seoLocation } = useHomepageGetSeoUrl({
    locationArea: location.area,
    locationId,
  })

  return (
    <BaseLayout
      showSearchBar
      includeRUMScripts={true}
      rumPageLabel="Homepage New"
      pageName="homepage"
      searchBarChildren={
        <div data-testid="mega-menu-container">
          <MegaMenu locationId={location.id} />
        </div>
      }
    >
      <HomepageSeoMetadata
        seoLocation={seoLocation}
        locationId={locationId}
        locationName={location.name}
      />
      <HomepageAdsWrapper locationId={locationId} shouldShowHomepageFeed={shouldShowHomepageFeed}>
        <ClientRender>
          <GptRightrailBottom />
        </ClientRender>

        <HeroCMSBanner locationId={locationId} />
        <HomepageContainer>
          <HomepageMain>
            <Spacing mBottom={spacing.husky}>
              <SimilarToFavouritesGallery locationId={locationId} />
            </Spacing>

            {!isUserAuthenticated(userStatus) || (!loadingFeed && !feedResults.length) ? (
              <FeedFallbackComponents
                getSeoUrl={getSRPUrl}
                location={location}
                userAgent={userAgent}
              />
            ) : (
              <HomepageSectionWrapper mTop={spacing.husky} mBottom={spacing.husky}>
                {priceDropDecision.enabled && (
                  <Spacing mBottom={spacing.husky}>
                    <PriceDrop />
                  </Spacing>
                )}
                <HomepageFeed
                  correlationId={correlationId}
                  feedPageInfo={feedPageInfo}
                  feedResults={feedResults}
                  getSeoUrl={getSRPUrl}
                  handleLoadMore={handleLoadMore}
                  loading={hpFeedLoadingMore}
                  loadingFeed={loadingFeed}
                  locationId={locationId}
                  locationName={location.name[apiLocale]}
                  shouldAutoFetchMore={shouldAutoFetchMore}
                  userAgent={userAgent}
                  userData={userData}
                />
              </HomepageSectionWrapper>
            )}
          </HomepageMain>

          <HomepageAside>
            <ClientRender>
              <GptRightrailTop />
            </ClientRender>

            <PopularOnKijiji seoLocation={seoLocation} />

            <GptRightrailMarketing />
          </HomepageAside>
        </HomepageContainer>

        <HomepageContainer>
          <ClientRender>
            <GptStickyHP />
          </ClientRender>
          {shouldShowHomepageFeed ? (
            <ScrollToTopButton />
          ) : (
            <HomepageSectionWrapper>
              <AppMarketingBanner />
            </HomepageSectionWrapper>
          )}
        </HomepageContainer>
      </HomepageAdsWrapper>
    </BaseLayout>
  )
}
