import { AdSource } from '@kijiji/generated/graphql-types'

import { trackEvent } from '@/lib/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'

import { AUTO_LOAD_COUNT, HP_FEED_REQUEST_CHUNKS } from '../useHomepageInfiniteFeed'

const getPartner = (source: string) => {
  return source === AdSource.Cas
    ? `partner_${source}`.toLowerCase()
    : source === AdSource.Move || source === AdSource.Organic
      ? source.toLowerCase()
      : ''
}

export const homepageFeedAdTracking = (adId: string, type: string, position: number) => {
  const partner = getPartner(type)

  trackEvent({
    action: GA_EVENT.SelectPromotion,
    label: `partner=${partner};partneradid=${adId};position=${position}`,
  })
}

export const homepageFeedPopularCategoryTracking = (categoryId: string, position: number) => {
  trackEvent({
    action: GA_EVENT.CategorySelected,
    label: `catId=${categoryId};btn=HPF;pos=${position}`,
  })
}

export const homepageFeedBrandedCategoryTracking = (campaignId: string) => {
  trackEvent({
    action: GA_EVENT.BrandedCategorySelected,
    label: `btn=popular;to=BrandedSearch;campaignID=${campaignId}`,
  })
}

export const homepageFeedLoadMoreTracking = (position: number) => {
  const loadMoreCount = Math.floor(position / (HP_FEED_REQUEST_CHUNKS * AUTO_LOAD_COUNT))

  trackEvent({ action: GA_EVENT.HPFLoadMore, label: `seq=${loadMoreCount}` })
}
